#total-container {
  width: 75%;
  margin: auto;
  margin-bottom: 200px;
  margin-top: 100px;
  max-width:1200px;
}

@media screen and (max-width: 792px) {
  #total-container { 
    width:90%;
    margin: 20px auto; }

}

#no-gifts-container {
  width: 75%;
  margin: auto;
  margin-bottom: 350px;
  margin-top: 100px;
}

#back-to-results-button {
  color: white;
  float: left;
  padding-left: 10px;
}

#group-gift-info-link {
  color: #4dddea;
  float: center;
  font-size: 16px;
  text-decoration: underline;
}

.popup-content {
  background: rgb(255, 255, 255);
  padding: 10px;
  color: black;
  border-radius: 20px;
  font-size: 14px;
}

.popup-content a {
  color: black;
}

.popup-content a:hover {
  color: grey;
}

#product-details-container {
  background-color: #363342;
  border-radius: 30px;
  margin-bottom: 100px;
  display: flex;
  min-height: 250px;
  padding: 25px;
}

#img-container {
  margin: 10px;
}

#product-image {
  width: 400px;
  height: 300px;
  border-radius: 12px;
}

#product-details {
  order: 2;
  float: left;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: auto;
  padding: 5% 5% 5% 5%;
}

#product-detail-brand {
  color: #aeafb7;
  text-align: left;
  margin-left: 10px;
}

#product-detail-title {
  color: white;
  text-align: left;
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin-left: 10px;
}

#product-detail-description {
  color: white;
  text-align: left;
  overflow: hidden;
  font-size: 14px;
  margin-right: 10px;
  margin-left: 10px;
}

#product-detail-price {
  color: white;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
}
#product-button-group {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}

@media (max-width: 800px) {
  #product-button-group {
    flex-direction: column;
  }
}

#share-button {
  margin-right: 10px;
  color: white;
  border: 1px solid white;
  border-radius: 12px;
  padding:2px 10px;
  display: flex;
    align-items: center;
    margin:auto;
    width: 114px;

}

.sharebuttonstyling {padding:0px 8px;}
#share-text {
  float: left;
  font-size:18px;
}

#share-icon {
  float: left;
  margin-left: 5px;
  margin-top:2px;
}

#share-button a {
  margin: auto;
  text-decoration: none;
  color: white;
}

#card_button_a {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
  background-color: #4dddea;
  border-radius: 12px;
  margin:4px;
  font-size:18px;
}
#card_button_a a {
  margin: auto;
  text-decoration: none;
  color: black;
  overflow-wrap: break-word;
}

#card {
  border-radius: 12px;
  padding: 25px;
  color: white;
  font-size: 20px;
}
