#emailCaptureContainer {
  display: flex;
  margin-top: 10vh;
  justify-content: center;

  flex-direction: column;
  h2 {
    text-align: center;
    font-weight: normal;
  }
}

#submitButton {
  background-color: #363342;
  color: whitesmoke;
  min-width: 10vw;
  min-height: 5vw;
  text-align: center;
  padding-right: 3vw;
  padding-left: 3vw;
  padding-top: 1vh;
  border-radius: 10px;
  font-size: var(--paragraphFontSize);
}
