/* MAIN PAGE */

body {
  background-image: url("GP_Gradient_3\ 1.svg");
  background-size: cover;
  background-color: #cccccc;
}

/*
.App {
  text-align: center;
  font-family: 'Glacial Indifference', sans-serif;
  font-size: .8vw;

}*/

/*
.logo {
  height: 250px;
  width: 250px;
  background-color: transparent;
  margin:0%;
}

.App-toplogo {
  width: 100%;
  height: 4em;
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  display: flex;
  justify-content: left;
  margin-left: 2em;
}
*/

/*

.toplogo {
  width: auto;
  height: 100%;
}

.App-title {
  font-family: "Sofia Pro", sans-serif; 
  letter-spacing: 2.5px;
  margin: 2em;
  color: calc(black-50);

}
*/
/*
#App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}
*/

.App-link {
  color: black;
}

.about {
  margin-left: 10vw;
}

/* QUZ PAGE */

.choicesContainer {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  max-width: 315px;
  margin: auto;
}

.Question {
  max-width: 650px;
  text-align: center;
  margin: auto;
}

.columns {
  width: 80%;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  gap: 1.4em;
  justify-content: center;
}

.column {
  max-width: 350px;
  text-align: left;

  /*box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);*/
  /*background-color: white;*/
}

.column p {
  font-size: calc(5px + 1vmin);
}

.content_container {
  min-height: 82vh;
}

/* RESULTS PAGE */

.results {
  width: 100vw;
}

img {
  height: 350px;
  width: 350px;
  object-fit: cover;
}

/* ALL PAGES */

.button_startOver {
  color: #aeafb7;
  background-color: none;
  font-size: 16px;
}
.button_startOver:hover {
  background-color: none;
}

.button_nav {
  /* padding: 10px 20px; */
  /* background-color: #a160fb; */
  /* color: white; */
  /* border: none;
  border-radius: 12px; */
  border-radius: 10px;
  margin: 0.3em;
  line-height: 55%;
}

.button_nav:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.button_nav:disabled:hover {
  cursor: default;
}

#loadingResults {
  margin: auto;
  width: 10vw;
  height: 10vw;
}

#button_changeScene:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

/*#button_changeScene:hover {
  background-color: #a160fb;
}*/

#footer {
  bottom: 0;
  background-color: black;
  width: 100%;
  font-size: calc(5px + 1vmin);
}

.fab.fa-instagram {
  color: transparent;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
}
/* 
.choiceButtons button {
  width: calc(90px + 2vmin);
  height: calc(45px + 2vmin);
  font-family: "Poppins", sans-serif;
  color: #000;
  background-color: #fff;
  border: 1.5px solid black;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin: 0;
  border-radius: 12px;
  font-size: calc(5px + 1vmin);
  padding: 5px 10px;
} */

button {
  /*width: calc(90px + 2vmin);
  height: calc(45px + 2vmin);*/
  /* width: auto;
  height: auto; */
  /* font-family: "Sofia Pro", sans-serif; */
  /* color: #000;
  background-color: #a060fb60; */
  /*border: 1.5px solid black;*/
  /*box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);*/
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border: 1px solid transparent;
  margin: 0;
  border-radius: 50px;
  font-size: 20px;
  padding: 5px 10px;
}

/*
button:hover {
  background-color: #a160fb;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  color: white;
}*/

#top {
  margin-top: 2em;
  align-items: center;
}

.Presently-logo {
  margin: 2em;
}

.brand-description p {
  margin: 0;
}

.Presently-logo a:link {
  color: black;
  text-decoration: none;
  font-family: "Pacifico", cursive;
}

.Presently-logo a:visited {
  color: black;
  text-decoration: none;
  font-family: "Pacifico", cursive;
}

.Presently-logo a:hover {
  color: #a160fb;
  text-decoration: none;
  font-family: "Pacifico", cursive;
  transition: all 0.3s ease 0s;
}

.footer a:link {
  color: white;
  text-decoration: none;
}

.footer a:visited {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

a:link {
  color: white;
  text-decoration: underline;
}

a:visited {
  color: white;
  text-decoration: underline;
}

a:hover {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
  transition: all 0.3s ease 0s;
}

.question {
  font-size: calc(10px + 2vmin);
  margin-bottom: 10px;
}

.answers {
  font-weight: lighter;
  margin-bottom: 50px;
  font-size: 16px;
}

#submit {
  width: calc(220px + 2vw);
  height: calc(70px + 2vh);
  font-size: calc(10px + 2vmin);
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: bolder;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

/* HORIZONTAL SCROLL */

/* #container {
  width: 400px;
  overflow-x: scroll;
  justify-content: center;
  margin: auto;
}

#questionsContainer {
  display: inline-flex;
  justify-content: center;
  margin: auto;
}
 #questionContainer {
  float: left; 
  min-width: 400px; 
  margin-bottom: 1em;
} */

#choiceContainer {
  margin-top: 2em;
}

#questionContainer p {
  margin-top: 0;
}

#submit:hover {
  background-color: white;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  color: black;
  transform: translateY(-3px);
}

.flexcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
  margin: 30px;
}
